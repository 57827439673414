<template>
  <div class="container">
    <img
      src="@/assets/Black-Hole-HD-Photos.jpg"
      alt="black hole as 404 error"
      @click="$router.push('/')"
    />
    <div class="centered">
      <h1>404 - NOT FOUND</h1>
    </div>
  </div>
</template>

<style scoped>
img {
  opacity: 0.8;
}

h1 {
  font-size: 5rem;
  color: lightgreen;
  text-shadow: 2px 2px gray;
}

.container {
  width: 100%;
  position: relative;
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
